import React,{useEffect} from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import Header from '@solid-ui-blocks/Header/Block01'
import Terms from '@solid-ui-blocks/Terms/Block01'
import Footer from '@solid-ui-blocks/Footer/Block01'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import theme from '@flexiblocks/templates/saas-v2/_theme'

const TermsPage = props => {
  useEffect(() => {
    window.location.href = "/terms.pdf";
  }, []);
  return null;


  const { allBlockContent } = useStaticQuery(query)
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout theme={theme} {...props}>
      <Seo title='Rhodium11' />
      {/* Modals */}
      <ModalWithTabs content={content['authentication']} reverse />
      <ModalWithTabs content={content['contact']} />
      {/* Blocks */}
      <Header content={content['header']} menuJustify='space-between' />
      <Divider space='5' />
      <Terms variant='full' title='Terms & Conditions'>
      </Terms>
      <Footer content={content['footer']} />
    </Layout>
  )
}

const query = graphql`
  query siteTermsBlockContent {
    allBlockContent(
      filter: { page: { in: ["site/index", "site/shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`
export default TermsPage
